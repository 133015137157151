import { Switch, Route, Routes, BrowserRouter as Router} from 'react-router-dom';
// layouts
//
import Dashboard from '../pages/dashboard'

import App from '../pages/index'



// ----------------------------------------------------------------------
import { PrivateRoute } from "./auth.routes";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
          <Route  path="/" element={<App/>}/>

          
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route path="/dashboard"  element={<Dashboard/>} />
          </Route>        
      </Routes>
    </Router>
  );
}

