import React,{useState, useContext, useEffect} from 'react'
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';

import { getUsers, getSaques, cadastroDash, UpdateScreen, verifyScreen, UpdateMeta, 
  UpdateFrequencia, verifyScreenNotification, UpdateNotification, UpdateSaque
  ,getUserMeta, verifyFrequecia} from '../services/database'
import { useQuery as useQuery } from 'react-query'
import { AuthContext } from '../context/auth';
import Logo from '../logo.png'
import ReactLoading from 'react-loading';

const columns = [
  { key: 'id', name: 'ID' },
  { key: 'title', name: 'Title' }
];

const rows = [
  { id: 0, title: 'Example' },
  { id: 1, title: 'Demo' }
];

export default function Dashboard() {

  const [ value, setValue] = useState([])
  const [ value2, setValue2] = useState([])

  const [ data, setData] = useState(null)
  const [ data2, setData2] = useState(false)

  const [ modal, setModal] = useState(false)

  const [text, setText] = useState('')

  const [tempo, setTempo] = useState('')
  const [valor, setValor] = useState('')
  const [meta, setMeta] = useState('')

  const [load, setLoad] = useState(null)

  const [load1, setLoad1] = useState(null)
  const [load2, setLoad2] = useState(null)
  const [load3, setLoad3] = useState(null)

  const [layout, setLeyout] = useState(false)

  const { signed, singOut, user } = useContext(AuthContext);

  const [enableScreen, setEnable] = useState(null)

  const [ frequencia, setFrequencia] = useState(false)
  const [ dataMeta, setDataMeta] = useState(false)
  
  const [cadastro, setCadastro] = useState(null)
  const [notify, setNotify] = useState(null)

  useEffect(() => {
    userFrequencia()
    userMeta()
    userUser()
    userInfo()
    selectScreen()
    selectSNotify()
  },[])
  

  async function userMeta() {
    let res = await getUserMeta()
    console.log("meta", res.data[0].meta)

    if(res.data.length >= 0) {
      setDataMeta(res.data[0])
    }
    return res
  }

  async function userFrequencia() {
    let res = await verifyFrequecia()

    if(res.data.length >= 0) {
      setFrequencia(res.data[0])
    }
    return res
  }


  async function userUser() {
    let res = await getUsers()
    console.log("chefrer", res.data.length > 0)

    if(res.data.length >= 0) {
      setData(res.data)
    }
    return res
  }


  async function selectScreen() {
    let res = await verifyScreen('cadastro')

    setCadastro(res.data[0])
  }

  async function selectSNotify() {
    let res = await verifyScreenNotification()

    setNotify(res.data[0])
    
  }

  async function userInfo() {
    let res = await getSaques()
    console.log("saques", res.data)

    if(res.data.length >= 0) {
      setData2(res.data)
    }


    return res
  }

  function handleNewUsers(){
    setLoad(true)
    console.log(text)
    let someText = text.replace(/(\r\n|\n|\r)/gm, "");

    const arr = someText.split(",")
    console.log(arr[2])


    var ultimo = arr[arr.length - 1];

    arr?.map((resp) => {
      if( ultimo == resp ){
        createUser(resp)
        userUser()
        setModal(false)
        window.location.reload()
      } else {
        createUser(resp)
      }
    })
    setLoad(false)
  }

  async function createUser(email) {
    console.log("cadastoo", email)
    let data = {email: email}
    if (email) {
      let res = await cadastroDash(data)
      return res
    }
  }

  async function UpdateScreenCadastro(valor){
    setLoad(true)
    let data = {enable: valor == 1 ? 1 : 0}
    
    let res = await UpdateScreen(data)

    let l = await selectScreen()
    setLoad(false)
    return res
  }


  async function UpdateScreenNotify(valor){
    setLoad1(true)
    let data = {enable: valor == 1 ? 1 : 0}
    
    let res = await UpdateNotification(data)

    let l = await selectSNotify()
    setLoad1(false)
    return res
  }
  

  async function UpdateScreenFrequencia(){
    setLoad2(true)
    let data = {tempo: tempo, valor: valor}
    
    let res = await UpdateFrequencia(data)

    userFrequencia()


    setLoad2(false)
    return res
  }


  async function UpdateScreenMeta(){
    setLoad3(true)
    let data = {meta: meta}
    
    let res = await UpdateMeta(data)

    setLoad3(false)
    return res
  }

  async function handleUpdateSaque(status, id){
    
    const data = {status: status, id: id}
    console.log("update saque", data)
    
    let res= await UpdateSaque(data)
    userInfo()

    return res
  }

return (
<>
<div class="flex w-screen h-screen text-gray-700">

            {modal && (
              <div className='z-30' style={{background: 'rgba(000,000,000,0.6)', width: '100vw', height: '100%', position: 'fixed'}}>
                <div className='flex justify-between p-4'>
                  <div></div>
                  <div onClick={()=> setModal(false)} className='flex cursor-pointer hover:bg-zinc-200 items-center justify-center bg-zinc-100 w-8 h-8 rounded-full'><h1>X</h1></div>
                </div>

                <div className='flex flex-col items-center justify-center mt-8'>
                  <div className='bg-zinc-100 w-[50%] h-[50px] items-center justify-cneter flex text-center'>
                    <h1 className='ml-4 font-bold'>cadastrar novos usuarios</h1>
                  </div>
                  <div className='bg-[#eee] h-[1px]'></div>
                  <textarea value={text} onChange={(e)=> setText(e.target.value)} className='w-[50%]' name="" id="" cols="30" rows="10"></textarea>
                  <button onClick={()=> handleNewUsers()} className='bg-blue-200 hover:bg-blue-100 p-4 w-[50%]'>
                    {load ? <ReactLoading type={'spinningBubbles'} color={'#000'} height={20} width={20} /> : <span> criar usuarios</span>}
                  </button>
                 
                </div>
              </div>

            )}

            <div class="flex flex-col items-center w-16 pb-4 overflow-auto border-r border-gray-300">
                <a class="flex items-center justify-center flex-shrink-0 w-full h-16 bg-gray-300" href="#">
                    <img className='w-8' src={Logo} alt="" srcset="" />
                </a>
                <a class="flex items-center justify-center flex-shrink-0 w-10 h-10 mt-4 rounded hover:bg-gray-300" href="#">
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                </a>
            </div>
            <div class="flex flex-col w-56 border-r border-gray-300">
                <button class="relative text-sm focus:outline-none group">
                    <div class="flex items-center justify-between w-full h-16 px-4 border-b border-gray-300 hover:bg-gray-300">
                        <span class="font-medium">
                            Tiktok company
                        </span> 
                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="absolute z-10 flex-col items-start hidden w-full pb-1 bg-white shadow-lg group-focus:flex">
                        <a class="w-full px-4 py-2 text-left hover:bg-gray-300" href="#">Tiktok web</a>
                        <a class="w-full px-4 py-2 text-left hover:bg-gray-300" href="#">Tiktok app</a>
                    </div>
                </button>
                <div class="flex flex-col flex-grow p-4 overflow-auto">
                    <a onClick={()=> setLeyout(false)} class="flex items-center flex-shrink-0 h-10 px-2 text-sm font-medium rounded hover:bg-gray-300" href="#">
                        <span class="leading-none">Painel</span>
                    </a>

                    <a onClick={()=> setLeyout(true)} class="flex items-center flex-shrink-0 h-10 px-2 text-sm font-medium rounded hover:bg-gray-300" href="#">
                        <span class="leading-none">Layout</span>
                    </a>
                    
                    <a onClick={()=> setModal(true)} class="flex items-center flex-shrink-0 h-10 px-3 mt-auto text-sm font-medium bg-gray-200 rounded hover:bg-gray-300"
                        href="#">
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                        <span class="ml-2 leading-none">Novo usuario</span>
                    </a>

                </div>

            </div>
            <div class="flex flex-col flex-grow">
                <div class="flex items-center flex-shrink-0 h-16 px-8 border-b border-gray-300">
                    <h1 class="text-lg font-medium">Analiticys</h1>
                    <button onClick={()=> singOut()} class="flex items-center justify-center h-10 px-4 ml-auto text-sm font-medium rounded hover:bg-gray-300">
                        Logout <i class="fa-solid fa-right-from-bracket ml-2"></i>
                    </button>

                </div>

                {layout ? 
                <>
                  <div class="flex-grow p-6 overflow-auto bg-gray-200">
                      
                      <h1>Tela de cadastro</h1>
                      <div class="grid grid-cols-1 mt-4 gap-12">
                          <div class="h-24 flex items-center justify-between col-span-1 bg-white border p-4 border-gray-300">
        
                            <div className='flex items-center'>
                              {cadastro?.enable == 0 ?
                                <button className='w-8 h-8 flex items-center justify-center rounded-full bg-red-200'>
                                </button>
                                  :
                                <button className='w-8 h-8 flex items-center justify-center rounded-full bg-green-200'>
                                </button>
                              }
                              <div>
                                <h1 className='text-xs ml-2'>Tela de cadastro</h1>
                                <h1 className='text-xs ml font-bold ml-2'>Status: {cadastro?.enable == 0 ? <>desativado</> : <>ativado</>}</h1>
                              </div>
                            </div>



                            {load ? <div className='mr-4'><ReactLoading type={'spinningBubbles'} color={'#000'} height={40} width={40} /></div>
                            :
                            <div className='flex flex-col'>
                              <button onClick={()=> [setEnable(true), UpdateScreenCadastro(1)]} className='bg-green-400 text-green-900 p-1 text-sm'>Ativar</button>
                              <button onClick={()=> [setEnable(false), UpdateScreenCadastro(0)]} className='bg-red-400 text-red-900 p-1 mt-2 text-sm' >Desativar</button>
                            </div>
                            }

                          </div>
                      </div>

                      <h1>Notificações</h1>
                      <div class="grid grid-cols-1 mt-4 gap-12">
                          <div class="h-24 flex items-center justify-between col-span-1 bg-white border p-4 border-gray-300">
        
                            <div className='flex items-center'>
                              {notify?.enable == 0 ?
                                <button className='w-8 h-8 flex items-center justify-center rounded-full bg-red-200'>
                                </button>
                                  :
                                <button className='w-8 h-8 flex items-center justify-center rounded-full bg-green-200'>
                                </button>
                              }
                              <div>
                                <h1 className='text-xs ml-2'>Push notification</h1>
                                <h1 className='text-xs ml font-bold ml-2'>Status: {notify?.enable == 0 ? <>desativado</> : <>ativado</>}</h1>
                              </div>
                            </div>



                            {load1 ? <div className='mr-4'><ReactLoading type={'spinningBubbles'} color={'#000'} height={40} width={40} /></div>
                            :
                            <div className='flex flex-col'>
                              <button onClick={()=> [setEnable(true), UpdateScreenNotify(1)]} className='bg-green-400 text-green-900 p-1 text-sm'>Ativar</button>
                              <button onClick={()=> [setEnable(false), UpdateScreenNotify(0)]} className='bg-red-400 p-1 text-red-900 mt-2 text-sm' >Desativar</button>
                            </div>
                            }


                          </div>
                      </div>

                      <h1 className='mt-4'>Configurações de Frequencia</h1>
                      <div class="grid grid-cols-1 mt-4 gap-12">
                          <div class="h-24 flex items-center col-span-1 justify-between bg-white border p-4 border-gray-300">
        
                            <div className='flex items-center'>
                              <div className='text-sm bg-zinc-100 p-4'>
                                <h1>Recompensar a cada: <span className='font-bold'>{frequencia?.tempo}</span></h1>
                                <h1>valor da recompensa: <span className='font-bold'>$ {frequencia?.valor}</span></h1>
                              </div>

                              <div className='flex flex-col ml-8'>
                                <h1 className='text-sm font-bold'>Recompensar a cada: </h1>
                                <input className='text-sm' value={tempo} onChange={(e)=> setTempo(e.target.value) } type="text" placeholder="insira o tempo em milesegundos" name="" id="" />
                              </div>

                              <div className='flex flex-col ml-8'>
                                <h1 className='text-sm font-bold'>Valor da recompensa: </h1>
                                <input className='text-sm' value={valor} onChange={(e)=> setValor(e.target.value) } type="text" placeholder="insira o valor da recompensa" name="" id="" />
                              </div>


                              </div>
                            {load2 ? <div className='mr-4'><ReactLoading type={'spinningBubbles'} color={'#000'} height={40} width={40} /></div>
                            :
                            <div className='flex flex-col'>
                              <button onClick={()=> [setEnable(true), UpdateScreenFrequencia()]} className='bg-yellow-400 text-sm text-yello-900 p-1'>Atualizar</button>
                            </div>
                            }

                          </div>
                      </div>

                      <h1 className='mt-4'>Valor da Meta</h1>
                      <div class="grid grid-cols-1 mt-4 gap-12">
                          <div class="h-24 flex items-center justify-between col-span-1 bg-white border p-4 border-gray-300">


                            <div className='flex items-center'>
                              <div className='text-sm bg-zinc-100 p-4'>
                                  <h1>Meta saque: <span className='font-bold'>$ {dataMeta?.meta}</span></h1>
                                </div>

                              <div className='flex flex-col ml-8'>
                                
                                <h1 className='text-sm font-bold'>Meta para solicitar o saque</h1>
                                <input className='text-sm' value={meta} onChange={(e)=> setMeta(e.target.value) }  type="text" placeholder='informe o valor da meta' />
                              
                              </div>
                            </div>

                            {load3 ? <div className='mr-4'><ReactLoading type={'spinningBubbles'} color={'#000'} height={40} width={40} /></div>
                            :
                            <div className='flex flex-col'>
                              <button onClick={()=> [setEnable(true), UpdateScreenMeta()]} className='bg-yellow-400 text-sm text-yello-900 p-1'>Atualizar</button>
                            </div>
                            }

                          </div>
                      </div>

                  </div>
                </> 
                : 
                <>
                  <div class="flex-grow p-6 overflow-auto bg-gray-200">
                      <div class="grid grid-cols-2 gap-6">
                          <div class="h-24 col-span-1 bg-white border p-4 border-gray-300">
                            <h1 className='text-xs'>Usuarios</h1>
                            <button className='w-8 h-8 flex items-center justify-center rounded-full bg-blue-200 mt-2'>
                              <h1 className='text-1xl'>{data?.length}</h1>
                            </button>
                          </div>
                          <div class="h-24 col-span-1 bg-white border p-4 border-gray-300">
                            <h1 className='text-xs'>Saques</h1>
                            <button className='w-8 h-8 flex items-center justify-center rounded-full bg-green-200 mt-2'>
                              <h1 className='text-1xl'>{data2?.length}</h1>
                            </button>
                          </div>
                      </div>



                      <div className='mt-4'>
                        <h1 className='pb-2 font-bold text-sm'>Todos os Saques</h1>
                        <div class="col-span-1 bg-white border border-gray-300">
                            <div className='flex justify-between p-4'>
                              <h1 className='w-[20%] font-bold text-sm text-gray-400'>id</h1>
                              <h1 className='w-[20%] font-bold text-sm text-gray-400'>email</h1>
                              <h1 className='w-[20%] font-bold text-sm text-gray-400'>valor</h1>
                              <h1 className='w-[20%] font-bold text-sm text-gray-400'>data do saque</h1>
                              <h1 className='w-[20%] font-bold text-sm text-gray-400'>status</h1>
                              <h1 className='w-[15%] font-bold text-sm text-gray-400'>ação</h1>
                            </div>
                            <hr />
                          {data2 && data2?.map((res) => (
                            <>
                              <div key={res.id} className='flex justify-between items-center hover:bg-zinc-100 p-4'>
                                <h1  className='w-[20%] text-sm'>{res.id}</h1>
                                <h1  className='w-[20%] text-sm'>{res.email_paypal}</h1>
                                <h1  className='w-[20%] text-sm'> ${res.valor_saque}</h1>                              
                                <h1  className='w-[20%] text-sm'>{res.data_saque}</h1>
                                <h1  className='w-[20%] text-sm'>{res.status}</h1>

                                <div  className='flex flex-col w-[15%]'>
                                  <button className="bg-green-400 text-sm text-green-900 p-1" onClick={()=> handleUpdateSaque('aprovado', res.id)}>aprovar</button>
                                  <button className='mt-2 bg-red-400 text-sm text-red-900 p-1'  onClick={()=> handleUpdateSaque('reprovado', res.id)}>recusar</button>
                                </div>
                              </div>
                              <hr />
                            </>
                            ))}
                        </div>

                      </div>
                    

                      <div className='mt-4'>
                        <h1 className='pb-2 font-bold text-sm'>Todos os Usuarios</h1>
                        <div class="min-h-24 col-span-1 bg-white border border-gray-300">
                            <div className='flex justify-between p-4'>
                              <h1 className='w-[25%] font-bold text-gray-400 text-sm'>id</h1>
                              <h1 className='w-[25%] font-bold text-gray-400 text-sm'>nome</h1>
                              <h1 className='w-[25%] font-bold text-gray-400 text-sm'>email</h1>
                              <h1 className='w-[13.5%] font-bold text-gray-400 text-sm'>status</h1>
                            </div>
                            <hr />
                            {data && data?.map((res) => (
                              <>
                              <div key={res.id} className='flex justify-between hover:bg-zinc-100 p-4'>
                                <h1 className='w-[25%] text-sm'>{res.id}</h1>
                                <h1 className='w-[25%] text-sm'>{res.nome}</h1>
                                <h1 className='w-[25%] text-sm'>{res.email}</h1>
                                {
                                  res.nome ? <h1 className='w-[13.5%] text-green-400 text-green-400 text-center rounded-[40px] border-2 border-green-400 text-xs font-bold'>ativo</h1>: <h1 className='w-[13.5%] text-yellow-400 rounded-[40px] border-2 border-yellow-400 text-center font-bold text-xs '>pendente</h1>
                                }
                                
                              </div>
                              <hr />
                              </>
                            ))}
                        </div>

                      </div>

                  </div>
                </>
                }
            </div>
            

</div>

</>
  )
}
