import {api} from './api'

export async function login(data) {
    const res = await api.post('/login/admin', data)
    return res
}

export async function cadastro(data) {
    const res = await api.post('/user', data)
    return res
}

export async function saque(data) {
        const res = await api.post('/saque', data)
    return res
}

export async function UpdateSaldo(data) {
    const res = await api.put('/user/saldo', data)
return res
}

export async function RestartGame(data) {
    const res = await api.put('/user/restart', data)
return res
}

export async function getUser(id) {
    const res = await api.get(`/user/${id}`)
    return res
}


export async function getUsers() {
    const res = await api.get(`/users`)
    return res
}

export async function getSaques() {
    const res = await api.get(`/saques`)
    return res
}

export async function verifyEmail() {
    const res = await api.get(`/verify/:email`)
    return res
}

export async function cadastroDash(data) {
    const res = await api.post(`/cadastro/user`, data)
    return res
}

export async function UpdateScreen(data) {
    const res = await api.put('/update/screen', data)
    return res
}

export async function verifyScreen(screen) {
    const res = await api.get(`/screen/${screen}`)
    return res
}

export async function verifyFrequecia() {
    const res = await api.get(`/frequencia`)
    return res
}

export async function UpdateMeta(data) {
    const res = await api.put('/meta/user', data)
    return res
}

export async function UpdateFrequencia(data) {
    const res = await api.put('/meta/frequecia', data)
    return res
}


export async function verifyScreenNotification() {
    const res = await api.get(`/notification`)
    return res
}

export async function UpdateNotification(data) {
    const res = await api.put('/update/notification', data)
    return res
}

export async function UpdateSaque(data) {
    const res = await api.put('/update/saque', data)
    return res
}


export async function loginAdmin(data) {
    const res = await api.post('/login/admin', data)
    return res
}

export async function getUserMeta() {
    const res = await api.get(`/meta`)
    return res
}

