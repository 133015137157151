import React,{useContext, useState} from 'react'
import logo from '../favicon.png';
import image2 from '../logo.png';
import { Link } from 'react-router-dom';
import '../index.css'
import { useTheme } from '../hooks/useTheme';
import { AuthContext } from '../context/auth';
import { Navigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

export default function Index() {
  const {theme, setTheme} = useTheme()
  const { Login, signed } = useContext(AuthContext);
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [load, seLoad] = useState(null)


  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      return (false)
  }


  async function authentication() {
    seLoad(true)
    const data = {email: email, senha: pass}
    if(ValidateEmail(email)) {
      try {
        let res = await Login(data)
        if( res.data.email ) {
          window.location.href = '/dashboard'
        } else {
          seLoad(false)
        }
      } catch {
        seLoad(false)
      }
    } else {
      seLoad(false)
    }

  }

  if (!signed) {
    return (
      <div className='App p-4 flex items-center flex-col bg-w justify-center dark:bg-zinc-900'>
        <div  style={{height: '100vh'}} className='flex sm:flex-row flex-col dark:text-white rounded-md w-full sm:w-[40%] justify-between items-center'>
            <div className='flex flex-col w-full p-4 '>
                  <div className='flex flex-col items-center'>            
                    <img className='w-[80px] mt-8' src={image2} alt="logo" />
                    <h1 className='text-[black] dark:text-white text-2xl mt-4 ml-2 font-bold'>Faça login na sua conta</h1>
                  </div>

                  <div className='w-full h-[20px] mt-4 flex items-center justify-center'>
                    {load == false && <h1 className='text-red-400'>Correo electrónico o contraseña no válidos</h1>}
                  </div>

                  <input value={email} onChange={(e) => setEmail(e.target.value)} className='p-4 mt-8 w-full bg-gray-100 dark:bg-zinc-800 rounded-md' type="email" name="" placeholder='Escriba su correo electrónico' id="" />
                  <input value={pass} onChange={(e) => setPass(e.target.value)} className='p-4 mt-4 w-full bg-gray-100 dark:bg-zinc-800'  type="password" placeholder='Escribe tu contraseña'  name="" id="" />
                  <button onClick={()=> authentication()} className='p-4 bg-black dark:bg-zinc-800 dark:text-white text-white mt-4 text-center flex justify-center w-full font-bold rounded-md'>
                      {load ? <ReactLoading type={'spinningBubbles'} color={'#fff'} height={20} width={20} /> : <span>Hacer logi</span>}
                  </button>
            </div>
        </div>
      </div>
    )
  } else {
    return <Navigate to="/dashboard" />;

  }
}
