
import { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { login } from "../services/database";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loadingStoreData = () => {
      const storageUser = localStorage.getItem("@login-id");

      if (storageUser) {
        setUser(JSON.parse(storageUser));
      }
    };
    loadingStoreData();
  }, []);



  async function Login(resp) {
      const data = await login(resp)
      console.log('login', data)
      if(!data.data.code) {
        const id = await localStorage.setItem('@login-id', JSON.stringify(data.data));
        setUser(id)
      }
      return data
  }

  const singOut = () => {
    localStorage.clear();
    setUser(null);
    window.location.href = '/'
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        Login,
        singOut,
        signed: !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};